var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-final-modal',{attrs:{"name":"track-details-modal"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.content},[_c('button',{class:_vm.$style.closeModalButton,on:{"click":function($event){$event.preventDefault();_vm.showModal = false}}},[_c('BaseIcon',{attrs:{"icon":"times"}})],1),(_vm.fetchTrackDetailsStatusPending)?_c('div',[_c('p',{staticClass:"$style.loadingDetails"},[_c('BaseSpinner',{attrs:{"show":""}})],1)]):(_vm.fetchTrackDetailsStatusError)?_c('div',[_c('p',[_vm._v("There was a problem while fetching data")])]):(_vm.fetchTrackDetailsStatusSuccess && _vm.track)?_c('div',[_c('div',{class:_vm.$style.header},[_c('h2',[_vm._v(_vm._s(_vm.track.title))]),(_vm.track.confidence)?_c('div',{class:[
              _vm.$style.confidence,
              _vm.getConfidenceAlertStyle(_vm.track.confidence) ]},[_vm._v(" "+_vm._s(_vm.track.confidence)+"% confidence ")]):_vm._e()]),_c('ul',{class:_vm.$style.songDetailsList},[_c('li',[_c('span',{class:_vm.$style.highlight},[_vm._v("ISRC:")]),_c('span',[_vm._v(_vm._s(_vm.track.isrc || "N/A"))])]),_c('li',[_c('span',{class:_vm.$style.highlight},[_vm._v("HFA Code:")]),_c('span',[_vm._v(_vm._s(_vm.track.hfa_code || "N/A"))])]),_c('li',[_c('span',{class:_vm.$style.highlight},[_vm._v("ISWC:")]),_c('span',[_vm._v(_vm._s(_vm.track.iswc || "N/A"))])]),_c('li',[_c('span',{class:_vm.$style.highlight},[_vm._v("Performers:")]),_c('span',[_vm._v(_vm._s(_vm.track.performers || "N/A"))])])]),_c('div',{class:_vm.$style.detailsSectionContainer},[_c('div',{class:_vm.$style.detailSection},[_c('span',{class:[_vm.$style.highlight, _vm.$style.sectionTitle]},[_vm._v("Writers ("+_vm._s(_vm.track.writers.length)+")")]),_c('div',{class:_vm.$style.sectionGridContainer},[_c('div',{class:_vm.$style.sectionGrid},[_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Name")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Artist Name")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Role")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Society")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Ipi")])]),_vm._l((_vm.track.writers),function(ref,idx){
                  var alias = ref.alias;
                  var name = ref.name;
                  var interested_party = ref.interested_party;
                  var artist_name = ref.artist_name;
                  var role = ref.role;
                  var society = ref.society;
                  var ipi = ref.ipi;
return _c('div',{key:(interested_party + "-" + idx),class:_vm.$style.sectionGrid},[_c('span',[_vm._v(_vm._s(name || interested_party || "-"))]),_c('span',[_vm._v(_vm._s(alias || artist_name || "-"))]),_c('span',[_vm._v(_vm._s(role || "-"))]),_c('span',[_vm._v(_vm._s(society || "-"))]),_c('span',[_vm._v(_vm._s(ipi || "-"))])])})],2)]),_c('div',{class:_vm.$style.detailSection},[_c('span',{class:[_vm.$style.highlight, _vm.$style.sectionTitle]},[_vm._v("Publishers ("+_vm._s(_vm.track.publishers.length)+")")]),_c('div',{class:_vm.$style.sectionGridContainer},[_c('div',{class:_vm.$style.sectionGrid},[_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Name")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Admin")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Role")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Society")]),_c('span',{class:_vm.$style.columnHeadline},[_vm._v("Ipi")])]),_vm._l((_vm.track.publishers),function(ref,idx){
                  var alias = ref.alias;
                  var name = ref.name;
                  var interested_party = ref.interested_party;
                  var party_admin = ref.party_admin;
                  var role = ref.role;
                  var society = ref.society;
                  var ipi = ref.ipi;
return _c('div',{key:(interested_party + "-" + idx),class:_vm.$style.sectionGrid},[_c('span',[_vm._v(_vm._s(name || interested_party || "-"))]),_c('span',[_vm._v(_vm._s(alias || party_admin || "-"))]),_c('span',[_vm._v(_vm._s(role || "-"))]),_c('span',[_vm._v(_vm._s(society || "-"))]),_c('span',[_vm._v(_vm._s(ipi || "-"))])])})],2)])])]):_vm._e()]),_c('div',{class:_vm.$style.actions},[_c('div',{class:_vm.$style.pagination},[_c('div',[_c('BaseButton',{class:_vm.$style.paginationButton,attrs:{"disabled":!this.prevTrack},on:{"click":function($event){$event.preventDefault();return _vm.$emit('prev', _vm.prevTrack)}}},[_vm._v("Previous")])],1),_c('div',{class:_vm.$style.paginationDivider},[_vm._v("|")]),_c('div',[_c('BaseButton',{class:_vm.$style.paginationButton,attrs:{"disabled":!this.nextTrack},on:{"click":function($event){$event.preventDefault();return _vm.$emit('next', _vm.nextTrack)}}},[_vm._v("Next")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }